



















import { Component, Mixins } from "vue-property-decorator";
import HelperDinamicallyForm from "@/components/DinamicallyForm/HelperDinamicallyForm";
import departamentoModule from "@/store/modules/departamento-module";
import usuariosModule from "@/store/modules/usuario-module";
import { departamento } from "@/shared/dtos/departamento";
import empresaModule from "@/store/modules/empresa-module";

@Component({
  components: {},
})
export default class DepartamentosFormulario extends Mixins(
  HelperDinamicallyForm
) {
  public item_pantalla: departamento = new departamento();

  public async OnCreated() {
    this.ShowLoading();

    if (this.getparamId().HayParametro) {
      await departamentoModule.getdepartamento(this.getparamId().Parametro);
      this.item_pantalla = departamentoModule.departamento;
    }

    await empresaModule.getempresas();
    await usuariosModule.getusuarios();
    await departamentoModule.getdepartamentos();
    //Para la clonacion automatica
    this.BasicElement = this.item_pantalla;
    this.HideLoading();
  }

  public CreateFields() {
    //Empezamos a crear una pantalla
    //configuracion de la clonacion automatica

    this.AddFormItemDataDefaultString(
      this.item_pantalla.nombre,
      "Nombre",
      "nombre",
      100
    )
      .isRequired()
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultString(
      this.item_pantalla.ubicacion,
      "Ubicacion",
      "ubicacion",
      100
    ).Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_departamento,
      "Departamento principal",
      "id_departamento"
    )
      .CreateComboSingle(departamentoModule.departamentos, "nombre", "id")
      .Dimesiones(12, 10, 6);

    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_empresa,
      "Proveedor",
      "id_empresa"
    )
      .CreateComboSingle(empresaModule.empresas, "nombre", "id")
      .Dimesiones(12, 10, 6);
    this.AddFormItemDataDefaultNumber(
      this.item_pantalla.id_responsable,
      "Responsable",
      "id_responsable"
    )
      .CreateComboSingle(usuariosModule.usuarios, "nombre", "id")
      .Dimesiones(12, 10, 6)
      .isRequired();
  }

  public Insertar(object: departamento) {
    departamentoModule.guardardepartamento(object).then(() => {
      this.AllSaveOk();
    });
  }

  public Actualizar(object: departamento) {
    departamentoModule.modificardepartamento(object).then(() => {
      this.AllSaveOk(); //guardo correctamente y me cierro
    });
  }
}
