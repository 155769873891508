import { BaseDto } from "@/shared/dtos/base-dto";
import { empresa } from "./empresa";

export class departamento extends BaseDto {
  public nombre!: string;
  public ubicacion!: string;
  public id_empresa!: number;
  public empresa!: empresa;
  public id_departamento!:number;
  public departamento!:departamento;
  public id_responsable!:number;

}
